import $ from 'jquery';
window.jQuery = $;

window.onbeforeunload = function () {
  if(window.scrollTo) window.scrollTo(0,0);
};

if(history && history.scrollRestoration) history.scrollRestoration = "manual";

$(document).ready(() => {
  const sr = ScrollReveal();

  document.addEventListener('lazybeforeunveil', function(e) {
    let fadein = $(e.target);
    if(fadein.length) sr.reveal(fadein.get(0));
  });

  let tweenDelay = .5

  $('.intro__mask').hide();

  gsap.from("#introImg", {duration:1, autoAlpha:0, webkitFilter:"blur(25px)", delay:tweenDelay});
  tweenDelay += 2;
  gsap.to(".intro", {duration:3, top:"-100vh", ease:"power2.inOut", delay:tweenDelay});
  gsap.to(".intro__image", {duration:3, top:"-150vh", ease:"power2.inOut", delay:tweenDelay});
  gsap.to(".hero", {duration:3, top:"0", ease:"power2.inOut", delay:tweenDelay, onComplete:function(){
    $('body').addClass('scroll');
    $('.intro').hide();
  }});
  gsap.to(".hero__logo", {duration:3, marginTop:"0", marginBottom:"30px", ease:"power2.inOut", delay:tweenDelay});

  initListeners();
});

const initListeners = () => {
  const nav = $('.nav'),
        cadboll = $('.cadboll'),
        toggleIcon = $('.nav__menu-toggle-icon'),
        navList = $('.nav__list');

  const onWindowScroll = () => {
    const scrollPos = $(window).scrollTop();

    if(scrollPos >= $('.hero').outerHeight()){
      nav.addClass('nav--sticky');
      cadboll.addClass('cadboll--sticky');
    } else {
      nav.removeClass('nav--sticky');
      cadboll.removeClass('cadboll--sticky');
    }
  };

  onWindowScroll();

  $(window).on('scroll', onWindowScroll);

  $('.nav__menu-toggle').on('click', function() {
    toggleIcon.toggleClass('open');
    navList.toggleClass('open');
    nav.toggleClass('open');
  });

  $('.nav__link').on('click', function(e){
    e.preventDefault();

    toggleIcon.removeClass('open');
    navList.removeClass('open');
    nav.removeClass('open');

    const navHeight = $(window).innerWidth() >= 640 ? 85 : 65;

    const section = $(this.hash),
          sectionPos = section.offset().top - navHeight;

    setTimeout(function(){
      $('html, body').animate({
        scrollTop: sectionPos
      }, 1000);
    }, 250);
  });

  $(document).keydown(function(evt) {
    if(evt.keyCode == 9) evt.preventDefault();
  });
};
